import axios from "axios";
export const instanceAPI = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
});

export const searchAPI = axios.create({
  baseURL: `${process.env.VUE_APP_DB_API_URL}`,
});
// export const searchAPI = axios.create({
//   baseURL: `${process.env.VUE_APP_ADMIN_API_URL}`,
// });
export const printerAPI = axios.create({
  baseURL: process.env.VUE_APP_BASE_PRINTER_URL,
  timeout: 10000,
});

export const logAPI = axios.create({
  baseURL: `${process.env.VUE_APP_LOG_API_URL}`,
  timeout: 10000,

});