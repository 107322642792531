<template>
  <div id="Todos">
    <b-row id="Container" class="d-flex align-items-start">
      <b-col class="product-table" sm="7">
        <h3>{{ foundOrder.ref }}&gt;{{ foundOrder.name }}</h3>
        <vue-good-table
          id="data_table"
          v-if="foundOrder.lines"
          :columns="fields"
          :rows="foundOrder.lines"
          max-height="420px"
          :row-style-class="rowStyleClassFn"
          :search-options="{
            enabled: true,
            placeholder: 'Tìm sản phẩm ',
          }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'name'">
              <span style="font-weight: bold; color: #0bd577">
                {{ props.row.name }}
              </span>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            <span v-if="props.column.field == 'doneQty'">
              <span>
                {{ props.row.done_qty }}
              </span>
            </span>
            <span v-if="props.column.field == 'is_vat'">
              <span>
                {{ props.row.vat == 0 ? "Không" : "Có" }}
              </span>
            </span>
            <b-button-group
              style="position: relative; z-index: 99"
              v-if="props.column.field == 'action'"
            >
              <b-button
                v-b-modal="'edit-modal'"
                @click="editing_product = props.row"
                variant="light"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>

              <b-button variant="light" @click="onRowClick(props)">
                <b-icon icon="eye"></b-icon>
              </b-button>
            </b-button-group>
          </template>
        </vue-good-table>

        <!-- Modal for editing qty -->
        <b-modal
          data-backdrop="static"
          v-if="editing_product"
          id="edit-modal"
          :title="editing_product.name"
          @ok="handleOk"
          @hidden="resetModal"
          @hide="handleHide"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <div class="mb-3">
              <span
                >Số lượng hiện tại:&nbsp;
                <span class="font-weight-bold">{{
                  editing_product.done_qty
                }}</span>
              </span>
            </div>
            <b-form-group
              label="Số lượng mới"
              label-for="qty-input"
              invalid-feedback="Số lượng không được âm"
              :state="qtyState"
            >
              <b-form-input
                autofocus
                v-model="newQty"
                id="qty-input"
                required
                placeholder="Nhập số lượng mới"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Mã nhân viên"
              label-for="emp_code"
              invalid-feedback="Mã nhân viên không được trống"
              :state="empState"
            >
              <b-form-input
                maxlength="7"
                v-model="empCode"
                id="emp-input"
                required
                placeholder="Nhập mã nhân viên"
              />
            </b-form-group>
            <b-btn v-b-toggle.collapse-info variant="info"
              >Kiểm tra thông tin</b-btn
            >
          </form>
          <b-collapse
            id="collapse-info"
            :visible="collapseState"
            role="tabpanel"
          >
            <b-card class="mt-3">
              <!-- <pre class="m-0">{{ logData }}</pre> -->
              <div class="m-0">
                <h5>Sản phẩm: {{ editing_product.name }}</h5>
                <h5>
                  Số lượng cũ:
                  <b-badge variant="secondary">{{
                    editing_product.done_qty
                  }}</b-badge>
                </h5>

                <h5>
                  Số lượng mới:
                  <b-badge v-show="newQty" variant="success">{{
                    newQty
                  }}</b-badge>
                </h5>
                <h5>
                  Mã nhân viên:
                  <b-badge v-show="empCode" variant="success">
                    {{ empCode }}</b-badge
                  >
                </h5>
                <h5>Phiếu: {{ foundOrder.name }}</h5>
              </div>
            </b-card>
          </b-collapse>
          <b-row class="mt-3">
            <b-col>
              <div class="d-flex justify-content-center mb-3">
                <b-spinner
                  v-if="isUpdatingLog"
                  variant="success"
                  label="Loading..."
                ></b-spinner>
              </div>
              <b-alert
                class="align-self-center"
                :variant="modal_alert_state"
                :show="modal_alert && modal_alert.length > 0"
              >
                <span>{{ modal_alert }}</span>
                <a
                  v-if="modal_alert_state === 'success'"
                  @click="handleCloseModal"
                  href="#"
                  >Đóng</a
                ><br />
                <span v-if="modal_alert_state === 'danger'">
                  Xảy ra lỗi khi cập nhật log, cập nhật thủ công tại
                  <a href="/logs">đây</a>
                </span>
              </b-alert>
            </b-col>
          </b-row>
        </b-modal>

        <b-row align-v="center">
          <b-col sm="5">
            <b-btn variant="info" id="refreshBtn" @click="updateTable"
              >Cập nhật sản phẩm vừa in</b-btn
            >
          </b-col>
          <b-col sm="7">
            <b-btn
              :disabled="isOrderFinished"
              v-on:click="
                !Boolean(foundOrder.status) ? finishUp() : disableFinishAlert()
              "
              id="finishBtn"
              variant="success"
            >
              {{
                !Boolean(foundOrder.status) ? "Cập nhật phiếu" : "Đã cập nhật "
              }}
            </b-btn>
          </b-col>
        </b-row>
      </b-col>

      <b-col v-if="product.id" sm="5">
        <b-button v-b-toggle.collapse-print variant="primary" class="my-1"
          >Sản phẩm vừa in</b-button
        >
        <b-collapse visible id="collapse-print" class="my-1">
          <b-card
            no-body
            class="overflow-hidden"
            border-variant="info"
            header-bg-variant="info"
            header-text-variant="white"
          >
            <b-row no-gutters>
              <b-col md="5" align-self="center" class="mx-auto">
                <b-card-body>
                  <h5>
                    {{ print_product.name }}
                  </h5></b-card-body
                >
              </b-col>
              <b-col md="2" align-self="center" class="mx-4">
                <h3>
                  <b-badge>{{ sensor_count }}</b-badge>
                </h3>
              </b-col>
              <b-col md="3" align-self="center">
                <b-card-img
                  width="10"
                  height="70"
                  :src="
                    print_product.image_url
                      ? print_product.image_url
                      : 'https://assets.thuocsi.vn/assets/defaults/missing-e9cfa4812c342b9780b61700d2ade43591b3c5992f4dffedaa542c07d854b602.png'
                  "
                  :alt="print_product.name"
                  class="rounded-0"
                ></b-card-img>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
        <ProductDetail
          v-on:print-done="setPrintingProduct"
          :key="product.id"
          :pdetail="product"
        ></ProductDetail>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import { BIcon } from "bootstrap-vue";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("Inbound");
import ProductDetail from "./inProductDetail";
import { updateLotAndExdate } from "@/api/inboundServices";
import { makeToast } from "../Message";
import { printWithSensor } from "@/api/printerServices";
import { postLog } from "../../api/logServices";
export default {
  name: "Products",
  components: {
    ProductDetail,
    BIcon,
  },
  async created() {
    if (!localStorage.getItem(this.$route.params.id)) {
      await this.fetchProducts(this.$route.params.id).then(() => {
        if (
          !Object.prototype.hasOwnProperty.call(this.foundOrder, "startedAt")
        ) {
          let today = new Date();
          this.foundOrder.startedAt =
            this.getDate +
            " " +
            today.getHours() +
            ":" +
            today.getMinutes() +
            ":" +
            today.getSeconds();
        }
        localStorage.setItem(
          this.$route.params.id,
          JSON.stringify(this.foundOrder)
        );
      });
    } else {
      try {
        let Order = JSON.parse(localStorage.getItem(this.$route.params.id));
        this.$store.commit("Inbound/setOrder", Order);
        this.$store.commit("Inbound/setProducts", Order.lines);
      } catch (e) {
        console.log(e);
      }
    }
    this.$nextTick(() => {
      this.setFocusOnSearchBar();
    });
  },

  mounted() {
    this.interval = setInterval(this.getHours, 1000);
    this.addListener();
  },
  beforeDestroy() {
    this.removeListener();
  },
  data() {
    return {
      modal_alert: null,
      isEmpCodeSet: false,
      modal_alert_state: "light",
      empCode: null,
      newQty: null,
      qtyState: null,
      empState: null,
      editing_product: {},
      selectedIndex: -1,
      sensor_count: 0,
      fields: [
        { field: "product_id", label: "ID" },
        {
          field: "name",
          label: "Tên sản phẩm",
          tdClass: "ProductTable",
          width: "300px",
        },
        { field: "demand_qty", label: "Số lượng" },
        { field: "doneQty", label: "Đã in" },
        { field: "is_vat", label: "VAT" },
        { field: "action", label: "Hành động" },
      ],
      product: {},
      isUpdatingLog: false,
      products: [],
      time: "",
      interval: 0,
      createdAt: "",
      finishedAt: "",
      print_product: {},
      mock_result: {
        status: "OK",
        data: [
          {
            createdBy: "Ma nhan vien",
            createdTime: "2021-02-26T08:04:55.931Z",
            detail: {
              change_from: 5,
              change_to: 10,
              erp_product_id: 828838,
              ticket_code: "WH/IN/84848",
              updated_by: "Mã nhân viên",
              action_time: "",
            },
            key: ["WH/IN/84848"],
            sourceIP: "1.55.177.203",
            versionNo: "0272dab1-f709-4b5a-8199-9ddc5fefbc07",
          },
        ],
        message: "Create audit_log failed.",
      },
    };
  },
  methods: {
    ...mapActions(["fetchProducts", "updateCurrentProduct"]),
    toggleEmpCodeInput() {
      this.isEmpCodeSet = !this.isEmpCodeSet;
    },
    closeEditModal() {
      this.$bvModal.hide("edit-modal");
    },
    handleHide(e) {
      if (e.trigger === "backdrop") {
        e.preventDefault();
      }
    },
    resetModal() {
      this.newQty = null;
      this.qtyState = null;
      this.modal_alert = null;
      this.modal_alert_state = "light";
    },
    checkFormValidity() {
      let valid = this.$refs.form.checkValidity();
      this.qtyState = this.newQty
        ? parseInt(this.newQty) >= 0
          ? true
          : false
        : false;
      this.empState = this.empCode
        ? this.empCode.length >= 0
          ? true
          : false
        : false;
      return valid && this.qtyState && this.empState;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      // try setting local quanity first
      const productIndex = this.foundOrder.lines.findIndex(
        (p) => p.id == this.editing_product.id
      );
      if (productIndex > -1) {
        this.foundOrder.lines[productIndex].done_qty = parseInt(this.newQty);
      } else this.modal_alert = "Lỗi: Không tìm thấy sản phẩm";

      // try updating log to db
      this.isUpdatingLog = true;
      postLog(this.logData)
        .then((response) => {
          this.isUpdatingLog = false;
          if (response.status === 200) {
            this.modal_alert_state = "success";
            this.modal_alert = response.data.message;
          }
        })
        .catch((error) => {
          this.isUpdatingLog = false;
          this.modal_alert_state = "danger";
          if (error.response) {
            if (error.response.data.errorCode)
              this.modal_alert = error.response.data.errorCode;
          } else this.modal_alert = "UNKNOWN_ERROR | CORS_ERROR";
          this.updateLogToLocalStorage();
        });
      // Update to localStorage
      localStorage.setItem(this.foundOrder.id, JSON.stringify(this.foundOrder));
      // Hide the modal manually

      // this.$nextTick(() => {
      //   this.$bvModal.hide("edit-modal");
      // });
    },
    handleCloseModal() {
      this.$bvModal.hide("edit-modal");
    },
    updateLogToLocalStorage() {
      let logs = [];
      if (localStorage.getItem("log")) {
        // console.log("logData");
        logs = JSON.parse(localStorage.getItem("log"));
        logs.push(this.logData);
      } else {
        logs.push(this.logData);
      }
      localStorage.setItem("log", JSON.stringify(logs));
    },
    disableFinishAlert() {
      alert("Chỉ được cập nhật phiếu 1 lần");
    },
    rowStyleClassFn(row) {
      if (this.selectedIndex == row.originalIndex) return "purple";
      return "";
    },
    addListener() {
      document.addEventListener("keydown", this.handleArrowKeyPress);
    },
    removeListener() {
      document.removeEventListener("keydown", this.handleArrowKeyPress);
    },
    handleArrowKeyPress(e) {
      if (e.key === "ArrowDown" || e.key === "ArrowUp") {
        e.preventDefault();
        let totalItems = this.foundOrder.lines.length;
        switch (e.key) {
          case "ArrowDown":
            if (this.selectedIndex < totalItems - 1) {
              this.selectedIndex += 1;
              this.product = this.foundOrder.lines[this.selectedIndex];
              this.scrollToRow(this.selectedIndex);
            }
            break;
          case "ArrowUp":
            totalItems = this.foundOrder.lines.length;
            if (this.selectedIndex > 0) {
              this.selectedIndex -= 1;
              this.product = this.foundOrder.lines[this.selectedIndex];
              this.scrollToRow(this.selectedIndex);
            }
            break;
          default:
            break;
        }
      }
    },
    handleKeyPress(e) {
      if (e.target.tagName !== "INPUT") {
        let input = document.querySelector("#true-input");
        input.focus();
        input.value = e.key;
        e.preventDefault();
      }
    },
    scrollToRow(index) {
      const tbody = document
        .getElementById("data_table")
        .querySelector("tbody");
      const row = tbody.querySelectorAll("tr")[index];
      row.scrollIntoView();
      this.selectedIndex = index;
    },
    getHours: function () {
      var today = new Date();
      this.time =
        this.getDate +
        " " +
        today.getHours() +
        ":" +
        today.getMinutes() +
        ":" +
        today.getSeconds();
    },
    async updateTable() {
      try {
        await this.updateCurrentProduct();
        this.clearSensorCount();
        makeToast(this, "success", "Cập nhật bảng thành công");
      } catch (e) {
        console.log(e.message);
        if (e.message == "NULL_PRODUCT") {
          makeToast(this, "danger", "Chưa có sản phẩm nào đang in");
        }
      }
    },
    printTable() {
      let active_tab = document
        .getElementById("data_table")
        .getElementsByClassName("vgt-responsive")[0]
        .getElementsByTagName();
      // .getElementsByTagName("tbody")[0].outerHTM;
      // let active_list = active_tab.childNodes[0];
      // console.log(active_tab);
      let newWin = window.open("");
      newWin.document.write(active_tab);
      newWin.print();
      // newWin.close();
    },
    setPrintingProduct(id) {
      this.setFocusOnSearchBar();
      let print_product = this.foundOrder.lines.find((pd) => pd.id == id);
      if (print_product) this.print_product = print_product;
    },
    setFocusOnSearchBar() {
      let searchDiv = document
        .getElementsByClassName("vgt-global-search__input")[0]
        .getElementsByTagName("input")[0];
      searchDiv.focus();
      // console.log(searchDiv);
    },
    clearSensorCount() {
      printWithSensor();
    },

    updatePOStatus(currentPO) {
      currentPO.status = 1;
      currentPO.finishedAt = this.time;
      localStorage.setItem(currentPO.id, JSON.stringify(currentPO));
    },

    async finishUp() {
      let currentPO = this.foundOrder;
      if (
        window.confirm(
          "Phiếu sẽ được cập nhật lên hệ thống, bạn chắc chắn chứ?"
        )
      ) {
        if (Object.keys(this.product).length > 0) {
          //update last print item
          try {
            await this.updateCurrentProduct();
            // call api to push updated-PO
          } catch (e) {
            if (e.message === "NULL_PRODUCT");
          } finally {
            this.clearSensorCount();
            updateLotAndExdate(currentPO).then((res) => {
              if (res.data && res.data.result.meta.status) {
                this.updatePOStatus(currentPO);
                makeToast(this, "success", res.data.result.meta.message);
                this.$router.go();
              } else makeToast(this, "danger", res.data.result.meta.message);
            });
          }
        } else {
          alert("Hãy in hoặc cập nhật ít nhất một sản phẩm");
        }
      }
    },
    onRowClick(params) {
      this.selectedIndex = params.index;
      this.product = this.allProducts.filter(
        (product) => product.id == params.row.id
      )[0];
    },
  },
  computed: {
    ...mapGetters(["allProducts", "foundOrder"]),
    isOrderFinished: function () {
      return Boolean(this.foundOrder.status);
    },
    logData: function () {
      const data = {
        key: [this.foundOrder.name],
        type: "INBOUND",
        action: "CHANGE_QUANTITY",
        createdBy: this.empCode ? this.empCode : "",
        detail: {
          updated_by: this.empCode ? this.empCode : "",
          action_time: this.time,
          updated_source_ip: "....",
          product_name: this.editing_product.name,
          change_from: parseInt(this.editing_product.done_qty),
          change_to: parseInt(this.newQty),
          erp_product_id: this.editing_product.id,
          ticket_code: this.foundOrder.name,
        },
      };
      return data;
    },
    getDate: function () {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = mm + "/" + dd + "/" + yyyy;
      return today;
    },
    collapseState: function () {
      return this.empCode && this.empCode.length >= 0 ? true : false;
    },
  },
  // beforeRouteLeave(to, from, next) {
  //   const answer = window.confirm("Bạn có chắc muốn rời khỏi trang?");
  //   if (answer) {
  //     next();
  //   } else {
  //     next(false);
  //   }
  // },
};
</script>

<style lang="scss">
.mini-img {
  overflow: auto;
  height: 100%;
  width: 100%;
}
#refresh {
  background-color: #0bd577;
  border: none;
}
.product-table {
  min-height: 70vh;
}
#Container {
  overflow: hidden;
  // background-color: #e6e8ef;
  margin-left: 5;
  margin-right: 5;
}
#Todos {
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 0.5rem;
  height: 100%;
}

#finishBtn {
  font-size: 1.5em;
  border: none;
  margin-top: 20px;
  background-color: #0bd577;
  width: 100%;
  height: 4rem;
}

#refreshBtn {
  font-size: 1.2em;
  border: none;
  margin-top: 20px;
  width: 100%;
  height: 4rem;
}
.detail-info {
  padding: 0px;
  display: grid;
  object-fit: cover;
  grid-template-columns: repeat(auto-fit, minmax(160px, auto));
  grid-gap: 0px 0px;
  // grid-template-columns: repeat(auto-fill, minmax);

  &__item:nth-child(1) {
    font-weight: bold;
    font-size: 2.2em;
    grid-row-start: 1;
    grid-row-end: 10;
    padding: 0 0 5px;
  }
  &__item {
    padding: 0.5em 1em;
  }
}
</style>
