import { render, staticRenderFns } from "./SO.vue?vue&type=template&id=70fc5c05&scoped=true&"
import script from "./SO.vue?vue&type=script&lang=js&"
export * from "./SO.vue?vue&type=script&lang=js&"
import style0 from "./SO.vue?vue&type=style&index=0&id=70fc5c05&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70fc5c05",
  null
  
)

export default component.exports