import { searchAPI } from "./index";
const key = process.env.VUE_APP_V2_KEY;
export const getProductsByName = (name) => {
  const config = {
    headers: {
      Authorization: key,
    },
    params: {
      name: name,
    }
  }
  return searchAPI.get(`warehouse/core/v1/product`, config);
};
export const getProductByAdminId = (admin_id) => {
  const config = {
    headers: {
      Authorization: key,
    },
    params: {
      adminId: admin_id,
    }
  }
  return searchAPI.get(`warehouse/core/v1/product`, config);
}
export const getProductsById = (product_id) => {
  const config = {
    headers: {
      Authorization: key,
    },
    params: {
      productId: product_id,
    }
  }
  return searchAPI.get(`warehouse/core/v1/product`, config);
};

