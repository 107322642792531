// import { instanceAPI } from "./index";
import { instanceAPI } from "./index";
import { authHeader } from "../_helpers";
const baseUrl = "/api/v1"
export const getInbounds = () => {
  return instanceAPI.post(
    `${baseUrl}/receipts`,
    {},
    { headers: { TOKEN: authHeader() } }
  );
};

export const getInbound = (id) => {
  return instanceAPI.post(
    `${baseUrl}/receipts/${id}`,
    {},
    { headers: { TOKEN: authHeader() } }
  );
};

export const getInboundByPO = (PO) => {
  return instanceAPI.post(
    `${baseUrl}/receipts`,
    {},
    { headers: { TOKEN: authHeader(), ref: PO } }
  );
};



export const updateLotAndExdate = (PO) => {
  const key = process.env.VUE_APP_ODOO_KEY;
  let PO_format = {
    picking_id: PO.id,
    lines: PO.lines,
  };
  return instanceAPI.post(
    `${baseUrl}/receipts/update_lot_and_exdate`,
    PO_format,
    { headers: { 'Api-Key': key } }
  );
};
