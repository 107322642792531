import { instanceAPI } from "./index";
const baseUrl = "/api/v1";
export const loginRequest = (auth) => {
  // console.log("config", `${process.env.VUE_APP_CONFIG_URL}`);
  return instanceAPI.post(`${baseUrl}/login`, auth);
};

export const logoutRequest = () => {
  return instanceAPI.post(`${baseUrl}/logout`, {});
};
