import { printerAPI } from "./index";

export const printWithSensor = (string_to_print) => {
  return printerAPI.get(`/PrintBarcodeWithSensor/`, {
    params: {
      string: string_to_print,
      posX: 40,
      posY: 40,
      type: localStorage.getItem("active_printer"),
    },
  });
};

export const printQuantity = (string, quantity) => {
  return printerAPI.get(`PrintBarcode/`, {
    params: {
      string: JSON.stringify(string),
      quantity: quantity,
      posX: 40,
      posY: 40,
      type: localStorage.getItem("active_printer"),
    },
  });
};

export const checkPrinter = () => {
  return printerAPI.get(`PrinterStatus/`);
}

export const getSensor = () => {
  return printerAPI.get(`GetSensor/`);
}

export const checkPLC = () => {
  return printerAPI.get(`PLCStatus/`);
}
export const printBlank = () => {
  return printerAPI.get(`PrintBarcode/`, {
    params: {
      string: "",
      quantity: 1,
      posX: 40,
      posY: 40,
    },
  });
};
