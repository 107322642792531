<template>
  <div class="jumbotron">
    <div class="container">
      <div class="row">
        <div class="col-sm-6 offset-sm-3">
          <div v-if="alert.message" :class="`alert ${alert.type}`">
            {{ alert.message }}
          </div>
          <b-container>
            <h1>Login</h1>
            <form @submit.prevent="handleSubmit">
              <div class="form-group">
                <label for="username">Username</label>
                <input
                  autofocus
                  type="text"
                  v-model="username"
                  name="username"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !username }"
                />
                <div v-show="submitted && !username" class="invalid-feedback">
                  Username is required
                </div>
              </div>
              <div class="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  v-model="password"
                  name="password"
                  class="form-control"
                  :class="{ 'is-invalid': submitted && !password }"
                />
                <div v-show="submitted && !password" class="invalid-feedback">
                  Password is required
                </div>
              </div>
              <div class="form-group">
                <button class="btn" :disabled="getStatus.loggingIn">
                  Login
                </button>
              </div>
            </form>
          </b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState, mapActions } from "vuex";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("Account");
const { mapState } = createNamespacedHelpers("Alert");
export default {
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
    };
  },
  computed: {
    ...mapGetters(["getStatus"]),
    ...mapState({ alert: (state) => state }),
  },
  created() {
    if (!localStorage.getItem("user")) this.logout();
    else this.$router.push("/inbound");
    this.$store.dispatch("Account/logout");
  },
  methods: {
    ...mapActions(["login", "logout", { clearAlert: "Alert/clear" }]),
    handleSubmit() {
      // console.log();
      this.submitted = true;
      const { username, password } = this;
      if (username && password) {
        this.login({ username, password });
      }
    },
  },
};
</script>

<style scoped>
input {
  /* border: transparent; */
}
.jumbotron {
  background-color: #fff;
}
h1 {
  font-size: 25pt;
  color: #0bd577;
  font-weight: bold;
}
button {
  color: #fff;
  font-size: 15pt;
  background-color: #0bd577;
  margin: auto;
  min-width: 100%;
}
</style>
